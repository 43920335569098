import http, { Service } from "./common";

const resource = "shipment";
class ShipmentService extends Service {
  async startShipment(id) {
    return http.post(`/${resource}/start`, { id });
  }

  async addPackage(shipmentId, packageCode) {
    const data = {
      shipmentId,
      packageCode,
    };
    return http.post(`/${resource}/addPackage`, data);
  }
  async addPackages(shipmentId, packages) {
    const data = {
      id: shipmentId,
      packageIds: packages,
    };
    return http.post(`/${resource}/addPackages`, data);
  }
  async removePackages(shipmentId, packages) {
    const data = {
      id: shipmentId,
      packageIds: packages,
    };
    return http.put(`/${resource}/removePackages`, data);
  }
  async storePrintLog(type, shipmentId, packageId) {
    let data = {
      type,
      id: shipmentId,
      packageId,
    };
    return http.post(`/${resource}/print`, data);
  }
}

export default new ShipmentService(resource);

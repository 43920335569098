<template>
  <div class="shipping-form grid">
    <label class="title">
      {{ $t("shipment.shipping_form_title") }}
    </label>
    <div
      class="section"
      style="width: 30%; text-align: center; padding-top: 15px"
    >
      <span style="font-weight: bold; font-size: 20px">Saigon Travel</span>
    </div>
    <div
      class="section"
      style="width: 55%; text-align: center; padding-top: 15px"
    >
      <label style="font-weight: bold; font-size: 20px">{{
        entity.packageCode
      }}</label>
    </div>
    <div class="section qrcode" style="width: 15%; text-align: center">
      <qrcode-vue :value="entity.packageCode" :size="50" level="H" />
    </div>
    <div class="section qrcode">
      <qrcode-vue :value="packageResource" :size="qrcodeSize" level="H" />
    </div>
    <div
      class="section grid"
      style="
        width: 70%;
        padding-left: 0.5rem;
        padding-top: 0.2rem;
        vertical-align: middle;
      "
    >
      <label for="receiver" style="width: 30%">{{
        $t("deliver.pieces")
      }}</label>
      <label style="width: 70%; font-weight: bold">{{
        receipt.packageNumber
      }}</label>
      <label for="receiver" style="width: 30%">{{
        $t("deliver.weight")
      }}</label>
      <label style="width: 70%; font-weight: bold"
        >{{ entity.weightLbs }} {{ $t("unit.lbs") }}/{{ weightKg }}
        {{ $t("unit.kg") }}</label
      >
      <label for="receiver" style="width: 100%">GIFT</label>
      <label style="width: 100%">{{ receipt.deliveryNote }}</label>
    </div>
    <div class="col-12 section grid" style="padding: 0rem">
      <label for="sendername" class="col-2">{{
        $t("deliver.send_from")
      }}</label>
      <label id="sendername" class="col-10">{{
        receipt.dSenderName || receipt.senderName
      }}</label>
      <!-- <label id="senderAddress" class="address">{{ senderAddress }}</label> -->
    </div>
    <div class="col-12 section grid" style="padding: 0rem">
      <label for="receiver" class="col-2">{{ $t("deliver.send_to") }}</label>
      <label id="recipientname" class="col-10" style="font-weight: bold">{{
        receipt.dRecipientName || receipt.recipientName
      }}</label>
      <label id="recipientaddress" class="address" style="font-weight: bold">{{
        recipientAddress
      }}</label>
    </div>
    <div class="col-12 section grid" style="padding: 0rem">
      <label
        for="receiver"
        style="width: 100%; padding-left: 0.5rem; padding-top: 0.5rem"
        >{{ $t("shipment.shipping_form_content") }}</label
      >
      <div id="packageContent" class="col-12" style="min-height: 80px">
        {{ packageContent }}
      </div>
    </div>
    <!--
    <label style="width:25%;padding-left:0.5rem">{{$t('deliver.node')}}</label>
    <label style="width:25%;">
      {{receipt.deliveryNote}}
    </label>
    -->
    <div class="shipping-form-footer" style="font-style: italic">
      {{
        $t("shipment.shipping_form_footer", {
          printCopy: (entity.printCounter || 0) + 1,
          printTime,
        })
      }}
    </div>
  </div>
</template>
<script>
import ReceiptService from "@/service/ReceiptService";
import Utils from "@/service/Utils";
import { converter } from "@/utils/common";
import { DateTime } from "luxon";
export default {
  components: {},
  props: {
    receipt: {
      type: Object,
      default() {
        return {};
      },
    },
    entity: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    let resource = "package|" + this.entity.packageCode;
    resource = process.env.VUE_APP_BASE_URL + "#/" + Utils.toBase64(resource);
    return {
      packageResource: resource,
      qrcodeSize: 100,
    };
  },
  computed: {
    senderAddress() {
      return ReceiptService.getSenderAddress(this.receipt);
    },
    recipientAddress() {
      return ReceiptService.getRecipientAddress(this.receipt);
    },
    packageContent() {
      return "";
      //20231215 - Hide content
      // return Array.isArray(this.receipt.packageItemDtos)
      //   ? this.receipt.packageItemDtos
      //       .map((pkg) => pkg.quantity + " " + pkg.name)
      //       .join(", ")
      //   : "";

      // return Array.isArray(this.entity.itemDtos)
      //   ? this.entity.itemDtos
      //       .map((pkg) => pkg.quantity + " " + pkg.name)
      //       .join(", ")
      //   : "";
    },
    weightKg() {
      if (this.entity.weightLbs) {
        return converter.tokg(this.entity.weightLbs);
      }
      return 0;
    },
    printTime() {
      const dateTime = DateTime.fromJSDate(new Date());
      return dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
  },
  mounted() {
    /*
    if(this.entity.receiptCode && Array.isArray(this.shipment.receipts)) {
      for(let i = 0; i < this.shipment.receipts.length; i++) {
        if (this.shipment.receipts[i].receiptCode == this.entity.receiptCode) {
          this.receipt = this.shipment.receipts[i];
          break;
        }
      }
    }
    */
  },
};
</script>
<style lang="scss" scoped>
.grid {
  margin: 0rem;
}
.logo {
  width: 100px;
  padding-left: 0.5rem;
}
.title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
  width: 100%;
  margin-top: 5px;
}
.address {
  width: 100%;
  padding-left: 0.5rem;
}
.footer {
  padding-bottom: 0rem;
}
.section {
  border: 1px solid #ced4da;
}
.qrcode {
  width: 30%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
@media print {
  .footer {
    page-break-after: always;
  }
}
/*
.grid > [class*=col] {
  padding: 0;
  border: 1px solid #ced4da;
}
*/
</style>

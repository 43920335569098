class Utils {
  toBase64(text) {
    const buff = new Buffer(text);
    const base64data = buff.toString('base64');
    return base64data;
  }

  fromBase64(base64data) {
    const buff = new Buffer(base64data, 'base64');
    let text = buff.toString('ascii');
    return text;
  }
  extractResource(qrCodeString) {
    if (qrCodeString) {
      const parts = qrCodeString.split('#/');
      if (Array.isArray(parts) && parts.length > 1) {
        const decodedString = this.fromBase64(parts[1]);
        if (decodedString) {
          return decodedString.split('|')
        }
      }
    }
  }
  getImageBase() {
    return process.env.VUE_APP_API_BASE_URL + '/image/'
  }
}

export default new Utils()

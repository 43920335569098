import http, { Service } from "./common";

const resource = "receipts";
class ReceiptService extends Service {
  async getUnpackedReceipt() {
    const res = await http.get(`/${resource}/unpacked`);
    return res.data;
  }
  async getUnshippedReceipt() {
    //Unfinished means some packages are unsent
    const res = await http.get(`/${resource}/unshipped`);
    return res.data;
  }
  async getLastReceiptCode() {
    const response = await http.get(`/${resource}/lastCode`);
    if (response.status == 200) {
      return response.data;
    }
  }
  async getRecentRecipients(senderPhone) {
    let queries = {
      senderPhone,
      detail: 0
    };
    const response = await this.filter(queries);
    return response.content;
  }
  async savePackages(receiptCode, listPackages) {
    let data = {
      receiptCode: receiptCode,
      packageDtos: listPackages,
    };
    const response = await http.post(`/${resource}/list`, data);
    return response.data;
  }
  async packReceipts(listIds) {
    const response = await http.put(`/${resource}/pack`, listIds);
    return response.data;
  }
  // async findByCode(code) {
  //   const response = await http.get(`/${resource}?receiptCode=${code}`);
  //   if (response.status == 200) {
  //     return response.data;
  //   } else {
  //     return [];
  //   }
  // }
  async changeReceiptStatus2Packed(listIds) {
    const response = await http.put(`/${resource}/update2packed`, listIds);
    return response.data;
  }
  async save(data) {
    if (data.id) {
      const res = await this.update(data.id, data);
      return res.data;
    }
    const res_1 = await this.create(data);
    return res_1.data;
  }

  findBySender(sender) {
    return http.get(`/${resource}?sender=${sender}`);
  }

  getSenderAddress(receipt) {
    if (receipt.dSenderAddress) {
      return receipt.dSenderAddress;
    }
    let arr = [];
    if (receipt.senderAddress) {
      arr.push(receipt.senderAddress);
    }
    if (receipt.senderCity) {
      arr.push(receipt.senderCity);
    }
    if (receipt.senderCounty) {
      arr.push(receipt.senderCounty);
    }
    if (receipt.senderState) {
      arr.push(receipt.senderState);
    }
    return arr.join(", ");
  }

  getRecipientAddress(receipt) {
    if (receipt.dRecipientAddress) {
      return receipt.dRecipientAddress;
    }
    let arr = [];
    if (receipt.recipientAddress) {
      arr.push(receipt.recipientAddress);
    }
    if (receipt.recipientWard) {
      arr.push(receipt.recipientWard);
    }
    if (receipt.recipientDistrict) {
      arr.push(receipt.recipientDistrict);
    }
    if (receipt.recipientProvince) {
      arr.push(receipt.recipientProvince);
    }
    return arr.join(", ");
  }
  async storePrintLog(type, receiptId, packageId) {
    let data = {
      type,
      id: receiptId,
      packageId,
    };
    return http.post(`/${resource}/print`, data);
  }
  async removePackage(receiptId, packageCode) {
    return http.delete(`/${resource}/package/${receiptId}/${packageCode}`);
  }
}

export default new ReceiptService(resource);

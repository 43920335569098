<template>
  <shipping-form
    v-for="pkgEntity in packages"
    :key="pkgEntity.id"
    :receipt="receipts[pkgEntity.receiptCode]"
    :entity="pkgEntity"
  />
  <div id="__loaded__" style="display: none">{{ loaded }}</div>
</template>
<script>
import ShipmentService from "@/service/ShipmentService";
import ReceiptService from "@/service/ReceiptService";
import ShippingForm from "./components/ShippingForm";
export default {
  components: { ShippingForm },
  data() {
    return {
      receipts: {},
      packages: [{}],
      loaded: "",
    };
  },
  methods: {},
  mounted() {
    const {resource, id, packageId} = this.$route.params;
    if (id) {
      if (resource == "shipment") {
        ShipmentService.get(id).then((data) => {
          let receipts = {};
          if (this.shipment && Array.isArray(this.shipment.receipts)) {
            for (let i = 0; i < this.shipment.receipts.length; i++) {
              receipts[this.shipment.receipts[i].receiptCode] =
                this.shipment.receipts[i];
            }
          }
          this.receipts = receipts;
          if (packageId != undefined && packageId != null && Array.isArray(data.packages)) {
            this.packages = data.packages.filter(item => item.id.toString() == packageId);
          } else {
            this.packages = data.packages;
          }
          this.loaded = "loaded";
        });
      } else if (resource == "receipt") {
        ReceiptService.get(id).then((data) => {
          this.receipts = {
            [data.receiptCode]: data,
          };
          if (packageId != undefined && packageId != null && Array.isArray(data.packageEntities)) {
            this.packages = data.packageEntities.filter(item => item.id.toString() == packageId);
          } else {
            this.packages = data.packageEntities;
          }
          this.loaded = "loaded";
        });
      }
    }
  },
};
</script>

<style type="text/css">
@page {
  /*size: A5 landscape;*/
  size: A4 portrait;
  margin-left: 5mm;
  margin-right: 5mm;
  margin-top: 1mm;
}
.shipping-form {
  width: 210mm;
  height: 287mm;
}
.footer {
  width: 210mm;
  border-bottom-style: dotted;
  border-width: thin;
}
/*
body {
  width: 210mm;
  height: 148.5mm;
  margin-left: 5mm;
  margin-right: 5mm;
  margin-top: 5mm;
}
*/
@media print {
  p.bodyText {
    font-family: verdana, arial, sans-serif;
  }
  layout-print {
    min-width: 600px;
  }
  html,
  body {
    width: 210mm;
    height: 287mm;
  }
  .footer {
    width: 210mm;
    border-bottom-style: dotted;
    border-width: 0px;
  }
}
</style>
